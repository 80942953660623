/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, Image, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <ColumnCover name={"menu"}>
        </ColumnCover>


        <Column className="--menu pb--10 pt--10" menu={true}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box" content={"<a href=\"/\">Mateřská škola <span style=\"font-weight: bold;\">Litultovice</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"<span style=\"color: rgb(115, 71, 156);\">Aktuálně</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/dokumenty"} target={""} content={"<span style=\"color: rgb(235, 131, 207);\">Dokumenty</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/pro-rodice"} target={""} content={"<span style=\"color: rgb(55, 116, 222);\">Pro rodiče</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/fotogalerie"} target={""} content={"<span style=\"caret-color: rgb(221, 116, 15); color: rgb(221, 116, 15);\">Fotogalerie</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2" href={"/o-nas"} target={""} content={"<span style=\"color: rgb(16, 156, 69);\">O nás</span>"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/kontakt"} target={""} content={"<span style=\"color: rgb(213, 4, 57); text-decoration: underline;\">Kontakt</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-qbd661 --justify pb--80 pr--0 pt--80" style={{"backgroundColor":"var(--color-variable-1)"}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="mt--0 pb--20 pr--0 pt--40" name={"kj5sl9zhhe"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--20 pt--0" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--left mt--0">
              
              <Title className="title-box" content={"<span style=\"font-weight: bold;\">Kontakt<br><br></span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--left" content={"<span style=\"color: rgb(221, 116, 15);\">Mateřská škola Litultovice, p.o.</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--left" content={"Litultovice 204, 747 55<br>IČO: 01844440\n<br>Tel.: 553 668 127 / 739 301 878 <br>Email: mslitultovice@seznam.cz\n<br>Dat. schr.: 86rw6dr<br><br><span style=\"color: rgb(0, 0, 0); font-weight: bold;\">Provozní doba: </span><span style=\"color: rgb(0, 0, 0); font-style: italic;\">6:15 - 16:00</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--left" style={{"maxWidth":334}} content={"<br>"}>
              </Text>

              <Image style={{"maxWidth":360}} src={"https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=860x_.jpg"} svg={false} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13139/ea2cfab5debd4374b3229152f95e089c_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"vp7kskcabo"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"16"} place={"Litultovice 204"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--20 pt--20" style={{"backgroundColor":"rgba(221,116,15,1)"}} name={"mg1uexs8fmc"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: rgb(222, 222, 222);\">Zaměstnanci:</span>"}>
              </Subtitle>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(255, 255, 255);\">Jarmila Kořená\t– ředitelka školy\n<br>Bc. Lucie Kuchaříková\t– učitelka\n<br>Jana Dostálová – učitelka\n<br>Daniela Dzidová DiS. – učitelka\n<br>Martina Kremserová\t– školnice\n<br>Marie Dörrerová – provozní pracovnice</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
        </Column>

      </Layout>
    </ThemeWrapper>
  )
}